import React from 'react';
import './slider-fabrisorbdesigner.css';
import Designerpanel1 from '../../images/designer.webp';
import Designerpanel2 from '../../images/designer2.webp';
function slider() {
	return (

		<>
			<div id="slider-fabrisorbdesigner">
				<div id="carouselExampleIndicators5" className="carousel slide" data-ride="carousel" data-interval="2000">
					<ol className="carousel-indicators">
						<li data-target="#carouselExampleIndicators5" data-slide-to="0" className="active"></li>
						<li data-target="#carouselExampleIndicators5" data-slide-to="1"></li>
					</ol>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img className="d-block w-100" src={Designerpanel1} alt="Designerpanel" />
							<div className="carousel-caption d-none d-md-block">
								<h2>Designer Panels</h2>
							</div>
						</div>
						<div className="carousel-item">
							<img className="d-block w-100" src={Designerpanel2} alt="Designer Panels2" />
							<div className="carousel-caption d-none d-md-block">
								<h2>Designer Panels</h2>
							</div>
						</div>
					</div>
					<a className="carousel-control-prev" href="#carouselExampleIndicators5" role="button" data-slide="prev">
						<span className="carousel-control-prev-icon" aria-hidden="true"></span>
						<span className="sr-only">Previous</span>
					</a>
					<a className="carousel-control-next" href="#carouselExampleIndicators5" role="button" data-slide="next">
						<span className="carousel-control-next-icon" aria-hidden="true"></span>
						<span className="sr-only">Next</span>
					</a>
				</div>
			</div>
		</>
	);
}
export default slider;