import React from 'react';
import { Link } from 'react-router-dom';
import Greenbuilding from '../images/green-building.webp';
import Nationalsave from '../images/national-save.webp';
function sidebar() {
    return (

        <>
            <div className="container">
                <div className="row">
                    <div className="featured-products">
                        <h4>Key Products</h4>
                        <ul>
                            <li><Link to="/fabrisorb">FabriSorb</Link></li>
                            <li><Link to="/fabriwall">FabriWALL</Link></li>
                            <li><Link to="/decopanel">D'ecopanel</Link></li>
                            <li><Link to="/woodtec">WoodTEC</Link></li>
                            <li><Link to="/woodwool">Envirocoustic<sup>TM</sup> Wood Wool </Link></li>
                            <li><Link to="/foamstop-pu">FOAM STOP<sup>TM</sup> Polyurethane</Link></li>
                            <li><Link to="/polymax-polyester">Poly Max<sup>TM</sup></Link></li>
                            <li><Link to="/polysorb">Polysorb</Link></li>
                            <li><Link to="/soundproof-interior-doors">Acoustic Doors </Link></li>
                            <li><Link to="/echo-eliminator">Echo Eliminator<sup>TM</sup></Link></li>
                            <li><Link to="/mass-loaded-vinyl">Noise STOP<sup>TM</sup> Vinyl Barrier</Link></li>
                            <li><Link to="/decowood">Decowood</Link></li>
                            <li><Link to="/diffuso">Diffuso</Link></li>
                            <li><Link to="/polyester-ceiling-tiles">Polyster Ceiling Tiles</Link></li>
                            <li><Link to="/echotherm">Ecotherm</Link></li>
                            <li><Link to="/knaufdanoline">Knauf Danoline Kraft</Link></li>
                        </ul>
                    </div>
                    {/* 
                    <div className="featured-products">
                        <h4>Featured Products</h4>
                        <ul>
                            
                        </ul>
                    </div> */}

                    <div className="products-line">
                        <h4>Product Line</h4>
                        <ul>
                            <li><Link to="/absorptive-quilted-curtains">Absorptive Quilted Curtains</Link></li>
                            <li><Link to="/acoustic-foam">Acoustical Foam Panels</Link></li>
                            <li><Link to="/acoustics-underlayment">Acoustic Underlayment</Link></li>
                            <li><Link to="/cinema-port">Acoustic Windows </Link></li>
                            <li><Link to="/adjustable-door-seal-kits">Adjustable Jamb Seals</Link></li>
                            <li><Link to="/barrier-decoupler">Barrier – Decoupler </Link></li>
                            <li><Link to="/barrier-act-ceiling-tiles">Barrier ACT Ceiling Tiles</Link></li>
                            <li><Link to="/barrier-act-ceiling-tiles">Barrier-Backed Ceiling Tiles</Link></li>
                            <li><Link to="/cellulose-panels">CFAB Cellulose Panels</Link></li>
                            <li><Link to="/climate-seal-window-inserts">Climate Seal Window Inserts</Link></li>
                            <li><Link to="/cotton-soundwave-eggcrate-panels">Cotton Soundwave Eggcrate Panels</Link></li>
                            <li><Link to="/curve-panels">Curve Panels</Link></li>
                            <li><Link to="/dba-panels">dBa Ceiling Tiles</Link></li>
                            <li><Link to="/duracoustic">Duracoustic</Link></li>
                            <li><Link to="/hanging-acoustic-baffles">Echo Eliminator Baffles</Link></li>
                            <li><Link to="/sound-blankets">Exterior Quilted Barrier/Absorbers</Link></li>
                            <li><Link to="/glasswoolceiling">Glasswool Ceiling Tiles </Link></li>
                            <li><Link to="/green-glue">Green Glue Damping Compound</Link></li>
                            <li><Link to="/adjustable-door-seal-kits">Heavy Duty Door Seal Kits</Link></li>
                            <li><Link to="/adjustable-door-seal-kits">Magnetic Astragal</Link></li>
                            <li><Link to="/melamine-composite-panels">Melamine Composite Panels</Link></li>
                            <li><Link to="/painted-nubby-fiberglass-tiles">Painted Nubby Fiberglass Tiles</Link></li>
                            <li><Link to="/pvc-sailcloth-baffles">PVC and Sailcloth Baffles</Link></li>
                            <li><Link to="/pyramiddiffuser">Pyramid Diffuser </Link></li>
                            <li><Link to="/quiet-floor-np">Quiet Floor NP</Link></li>
                            <li><Link to="/quiet-floor-walk">Quiet Floor Walk</Link></li>
                            <li><Link to="/quilted-exterior-absorbers">Quilted Exterior Absorbers</Link></li>
                            <li><Link to="/rsic-1-clips">RSIC-1 Clips</Link></li>
                            <li><Link to="/rsic-1ext04">RSIC-1 Ext04 Clips</Link></li>
                            <li><Link to="/rubber-isolation-hangers">Rubber Isolation Hangers</Link></li>
                            <li><Link to="/sonex-one">Sonex One</Link></li>
                            <li><Link to="/sound-silencer-baffles">Sound Silencer Baffles</Link></li>
                            <li><Link to="/sound-silencer-ceiling-tiles">Sound Silencer™ Ceiling Tiles</Link></li>
                            <li><Link to="/spring-isolation-hangers">Spring Isolation Hangers</Link></li>
                            <li><Link to="/spring-isolation-mounts">Spring Isolation Mounts</Link></li>
                            <li><Link to="/squareline-ceiling-tiles">Squareline Ceiling Tiles</Link></li>
                            <li><Link to="/adjustable-door-seal-kits">Standard Door Seal Kits</Link></li>
                            <li><Link to="/stud-isolators">Stud Isolators</Link></li>
                            <li><Link to="/studio-windows">Studio Windows</Link></li>
                            <li><Link to="/super-w-pads">Super W Pads</Link></li>
                            <li><Link to="/foamstop-mm">FOAM STOP<sup>TM</sup> SoundWave</Link></li>
                            <li><Link to="/acoustical-stretch-fabric">AcoustyFABRIC</Link></li>
                            <li><Link to="/silk-metal-ceiling-tiles">Silk Metal<sup>TM</sup></Link></li>
                            <li><Link to="/sound-silencer">Sound Silencer<sup>TM</sup></Link></li>
                            <li><Link to="/soundbreak-xp">Sound Break XP<sup>TM</sup></Link></li>
                            <li><Link to="/ultra-touch-insulation">Ultra Touch Insulation</Link></li>
                            <li><Link to="/whisper-wave-clouds">Whisper Wave Clouds</Link></li>
                            <li><Link to="/ductsound">DUCTsound </Link></li>
                            <li><Link to="/acoustimetal">Noise STOP Acoustimetal<sup>TM</sup></Link></li>
                            <li><Link to="/squareline-ceiling-tiles">Metaline<sup>TM</sup></Link></li>
                        </ul>
                    </div>

                    <div className="line"></div>
                    <div className="ext-links">
                        <a href="http://www.usgbc.org/" target="_blank" rel="noopener noreferrer">
                            <img className="w-100 d-none d-sm-block" src={Greenbuilding} alt="Green Building 1" />
                            <img className="w-25 d-block d-sm-none" src={Greenbuilding} alt="Green Building 2" />
                        </a>
                        <a href="http://www.nationalsaveenergycoalition.org/" target="_blank" rel="noopener noreferrer">
                            <img className="w-100 d-none d-sm-block" src={Nationalsave} alt="National Save 1" />
                            <img className="w-25 d-block d-sm-none" src={Nationalsave} alt="National Save 2" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
}
export default sidebar;