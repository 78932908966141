import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import '../fabrisorb/fabrisorb-style.css';
import ScrollToTopButton from '../ScrollButton';
import SliderPolysorbDeco from './SliderPolysorbdeco';
import SliderPolysorbDecoWall from './Slider-polysorb-deco-wall';
import SliderPolysorbDecoCloud from './Slider-polysorb-deco-cloud';
import SliderPolysorbBuffles from './SliderPolysorbdecoBuffles';
function PolysorbDeco() {
    return (

        <>
            <Helmet>
                <title>Polysorb Deco Panels - Sustainable, Stylish Acoustic Solutions with Customizable Designs</title>
                <meta name="description" content="Polysorb Deco Panels combine eco-friendly, lightweight polyester fibers with exceptional sound absorption, creating a stylish and acoustically optimized environment. Customizable in color and fabric, they enhance both aesthetics and acoustic performance." />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Foamwood Panels" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabrisorb" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/fabrisorb" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/fabrisorb" />
            </Helmet>
            <Header />
            <section className="content" id="fabrisorb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className="col-sm-12 heading-fsorb">
                                    <h1>Polysorb-DECO</h1>
                                    <p>Polysorb acoustic panels create a great visual design statement whilst improving the acoustic response of your space along with a rich and luxurious feel. The uniqueness of our panels are that they are light weight, eco-friendly, sustainable polyester fibers which are made from 100% polyester (60% PET-recycled fiber, 40% PET-virgin fiber).<br /><br /> POLYSORB range of panels made from our Polymax polyester acoustical panels are non-allergenic, non-toxic, fungal-resistant, and do not contain chemical irritants or formaldehyde. Final finish and colour can be bespoken to meet any design challenge as we have a huge range of fabric choice to create stunning results envisaged by the architect / designer.</p>
                                    <SliderPolysorbDeco />

                                    <div className="flex-container">
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/polysorb-deco/polysorb-deco-brochure.pdf" className="btn btn-danger justify-content-center">Downloads</Link>
                                    </div>
                                </div>
                                <div className="col-sm-12 features">
                                    <h1>Features</h1>
                                    <div className="line"></div>
                                    <div className="row pad">
                                        <div className="col-md-8 col-sm-12">
                                            <ul>
                                                <li>High absorption values</li>
                                                <li>Available in various thickness and density as options</li>
                                                <li>High resistance to humidity</li>
                                                <li>Non-allergenic, non-toxic, fungal-resitant</li>
                                                <li>Does not contain chemical irritants or formaldehyde</li>
                                                <li>Can contribute to LEED V4.1 EQ Credit: Low-Emitting Materials</li>
                                                <li>Light weight and can be mounted easily</li>
                                                <li>Easily cleanable</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4 col-sm-12 tiles bgimage">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 features">
                                    <h1>Applications</h1>
                                    <div className="line"></div>
                                    <p>These acoustic wall panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                    <div className="row pad">
                                        <div className="col-md-6 col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Offices</li>
                                                <li>Schools</li>
                                                <li>Meeting Rooms</li>
                                                <li>Hotels</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Auditorium</li>
                                                <li>Recording Studios</li>
                                                <li>Conference halls</li>
                                                <li>Broadcasting Studios</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 printing">
                                    <div className="line"></div>
                                    <div className="col-sm-12 more">
                                        <h1>More Products</h1>
                                        <div className="line"></div>
                                    </div>
                                    <div className="accordion-started accordion-bral panels row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                                            <label className="ac-label" htmlFor="ac-1">
                                                <b>Polysorb-DECO</b><br />ACOUSTIC WALL PANELS<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <SliderPolysorbDecoWall />
                                                <p>Our POLYSORB-DECO wall panels are very easy to install, very decorative and can be glued or mounted to any surface. These are right solution to tame echo or reverberation in any open or closed space due to the material property. Since these panels are highly resistant to moisture and to fungal growth makes it makes POLYSORB the right choice to areas with high humidity such as swimming pools, restaurants, hotels, multipurpose halls, schools, places of worship such as churches, cinemas etc</p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active show" data-toggle="tab" href="#home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="home" className="tab-pane active show"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>40kg/m³ density polymax insulation with tissue facing</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness (mm)</th>
                                                                        <td>20, 25, 40, 50 mm, custom are also available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes (mm)</th>
                                                                        <td>600x600, 600x1200, 1000x1200, 1000x2400 & many more custom sizes </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.7 - 1.05 (varies with thickness and mounting)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Exposed</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Nonwoven fabric</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Wide range of standard colors.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classified as Class A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type A, E200 & E400</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive, Velcro, Impaling clips, Z clips etc</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu1" className="tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">NRC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">20mm</th>
                                                                        <td>0.07</td>
                                                                        <td>0.32</td>
                                                                        <td>0.7</td>
                                                                        <td>0.99</td>
                                                                        <td>0.93</td>
                                                                        <td>0.85</td>
                                                                        <td>0.7</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">25mm</th>
                                                                        <td>0.09</td>
                                                                        <td>0.40</td>
                                                                        <td>0.91</td>
                                                                        <td>0.97</td>
                                                                        <td>0.92</td>
                                                                        <td>0.86</td>
                                                                        <td>0.80</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">40mm</th>
                                                                        <td>0.17</td>
                                                                        <td>0.82</td>
                                                                        <td>0.94</td>
                                                                        <td>0.98</td>
                                                                        <td>0.88</td>
                                                                        <td>0.83</td>
                                                                        <td>0.90</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">50mm</th>
                                                                        <td>0.22</td>
                                                                        <td>0.81</td>
                                                                        <td>1.24</td>
                                                                        <td>1.30</td>
                                                                        <td>1.21</td>
                                                                        <td>1.16</td>
                                                                        <td>1.15</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu3" className="tab-pane fade"><br />
                                                            <br />
                                                            <h3>ACOUSTIC WALL PANELS : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.in/datasheet/polysorb-deco/polysorb-deco-acoustic-wall-panels.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line"></div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <label className="ac-label" htmlFor="ac-2">
                                                <b>Polysorb-DECO</b><br />ACOUSTICAL CEILING CLOUDS<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <SliderPolysorbDecoCloud />
                                                <p>POLYSORB clouds are the right choice to use as clouds where there is generally a tight space requirement due to the low ceiling height. These are apt for spaces where hygiene is a priority in the environment. Its hygienic surface which inhibits mould dust and bacteria helps to achieve a clean space. Its apt for restaurants, hotels, hospitals , class rooms, indoor swimming pools etc. These decorative sound absorbing clouds are great for restaurants, hotels, hospitals , class rooms, indoor swimming pools etc.</p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cp-menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="cp-home" className="container tab-pane active"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>40kg/m³ density polymax insulation with tissue facing</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness (mm)</th>
                                                                        <td>25, 50 mm, custom are also available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes (mm)</th>
                                                                        <td>600x600, 600x1200, 1000x1200, 1000x2400 & many more custom sizes</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.7 - 1.05 (varies with thickness and mounting)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Exposed</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Nonwoven fabric</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Wide range of standard colors.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classified as Class A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type A, E200 & E400</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive, Velcro, Impaling clips, Z clips, etc</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cp-menu1" className="container tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">Sabins</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">25mm</th>
                                                                        <td>4.55</td>
                                                                        <td>6.20</td>
                                                                        <td>8.42</td>
                                                                        <td>12.83</td>
                                                                        <td>14.35</td>
                                                                        <td>14.28</td>
                                                                        <td>10.45</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">50mm</th>
                                                                        <td>4.48</td>
                                                                        <td>8.00</td>
                                                                        <td>13.20</td>
                                                                        <td>17.93</td>
                                                                        <td>16.98</td>
                                                                        <td>16.35</td>
                                                                        <td>14.02</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cp-menu3" className="container tab-pane fade"><br />
                                                            <br />
                                                            <h3>ACOUSTICAL CEILING CLOUDS : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.in/datasheet/polysorb-deco/polysorb-deco-clouds.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line"></div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <label className="ac-label" htmlFor="ac-3">
                                                <b>POLYSORB-DECO</b><br />ACOUSTICAL BAFFLE<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <SliderPolysorbBuffles />
                                                <p>POLYSORB-DECO panels are light weight and can be easily hung as baffles or raft ceiling. Due to its strong and rigid edges give the baffles highly appealing and luxurious feel once installed. The fabric can be finished with various protective treatments such as scotchgard, flame retardant, stain resistant etc according to what the projects specification. Due to the property of the acoustic core these work well in highly humid conditions<br /><br />These decorative sound absorbing clouds are great for restaurants, hotels, hospitals , class rooms, indoor swimming pools etc.</p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" data-toggle="tab" href="#cb-home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cb-menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cb-menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="cb-home" className="container tab-pane active"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>40kg/m³ density polymax insulation with tissue facing</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness (mm)</th>
                                                                        <td>25, 50 mm, custom are also available</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes (mm)</th>
                                                                        <td>600x600, 600x1200, 1000x1200, 1000x2400 & many more custom sizes</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.7 - 1.05 (varies with thickness and mounting)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Exposed</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Nonwoven fabric</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Wide range of standard colors</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classified as Class A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Twist springs, wire clips, etc</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cb-menu1" className="container tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">Sabins</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">25mm</th>
                                                                        <td>4.55</td>
                                                                        <td>6.20</td>
                                                                        <td>8.42</td>
                                                                        <td>12.83</td>
                                                                        <td>14.35</td>
                                                                        <td>14.28</td>
                                                                        <td>10.45</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">50mm</th>
                                                                        <td>4.48</td>
                                                                        <td>8.00</td>
                                                                        <td>13.20</td>
                                                                        <td>17.93</td>
                                                                        <td>16.98</td>
                                                                        <td>16.35</td>
                                                                        <td>14.02</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cb-menu3" className="container tab-pane fade"><br />
                                                            <br />
                                                            <h3>ACOUSTICAL BAFFLE : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.in/datasheet/polysorb-deco/polysorb-deco-baffles.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default PolysorbDeco; 