import React from 'react';
import './slider-polysorb.css';
import Banner1 from '../../images/polysorb/banner1.webp';
import Banner2 from '../../images/polysorb/banner2.webp';
import Banner3 from '../../images/polysorb/banner3.webp';
import Banner4 from '../../images/polysorb/banner4.webp';
function slider() {
	return (
	  
	<>
	<div id="slider-polysorb">
		<div id="carouselExampleIndicators1" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators1" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators1" data-slide-to="1"></li>
    			<li data-target="#carouselExampleIndicators1" data-slide-to="2"></li>
    			<li data-target="#carouselExampleIndicators1" data-slide-to="3"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Banner1} alt="Wall Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Wall Panels</h1>
					</div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Banner2} alt="Ceiling Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Ceiling Panels</h1>
					</div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner3} alt="Ceiling Baffles" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Ceiling Baffles</h1>
					</div>
				</div>
				<div className="carousel-item">
      				<img className="d-block w-100" src={Banner4} alt="Designer Panels" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>Designer Panels</h1>
					</div>
				</div>
			</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators1" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators1" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default slider;