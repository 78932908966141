import React from 'react';
import './slider-foamstop-pu-cylinder.css';
import Cylinder1 from '../../images/cylinder1.webp';
import Cylinder2 from '../../images/cylinder2.webp';
function slider() {
	return (
	  
	<>
	<div id="slider-foamstop-pu-cylinder">
		<div id="carouselExampleIndicators4" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators4" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators4" data-slide-to="1"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Cylinder1} alt="Cylinder1" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>CYLINDRICAL DESIGN</h1>
					</div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Cylinder2} alt="Cylinder2" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h1>CYLINDRICAL DESIGN</h1>
					</div>
				</div>
			</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators4" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators4" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default slider;