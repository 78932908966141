import React from 'react';
import './slider-fabrisorb.css';
import Banner1 from '../../images/banner1.webp';
import Banner2 from '../../images/banner2.webp';
import Banner3 from '../../images/banner3.webp';
import Banner4 from '../../images/banner4.webp';
function slider() {
	return (

		<>
			<div id="slider-fabrisorb">
				<div id="carouselExampleIndicators1" className="carousel slide" data-ride="carousel" data-interval="2000">
					<ol className="carousel-indicators">
						<li data-target="#carouselExampleIndicators1" data-slide-to="0" className="active"></li>
						<li data-target="#carouselExampleIndicators1" data-slide-to="1"></li>
						<li data-target="#carouselExampleIndicators1" data-slide-to="2"></li>
						<li data-target="#carouselExampleIndicators1" data-slide-to="3"></li>
					</ol>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img className="d-block w-100" src={Banner1} alt="Ceiling Panels" />
							<div className="carousel-caption d-none d-md-block">
								<h2>Ceiling Panels</h2>
							</div>
						</div>
						<div className="carousel-item">
							<img className="d-block w-100" src={Banner2} alt="Designer Panels" />
							<div className="carousel-caption d-none d-md-block">
								<h2>Designer Panels</h2>
							</div>
						</div>
						<div className="carousel-item">
							<img className="d-block w-100" src={Banner3} alt="Wall Panels " />
							<div className="carousel-caption d-none d-md-block">
								<h2>Wall Panels</h2>
							</div>
						</div>
						<div className="carousel-item">
							<img className="d-block w-100" src={Banner4} alt="Ceiling Baffles" />
							<div className="carousel-caption d-none d-md-block">
								<h2>Ceiling Baffles</h2>
							</div>
						</div>
					</div>
					<a className="carousel-control-prev" href="#carouselExampleIndicators1" role="button" data-slide="prev">
						<span className="carousel-control-prev-icon" aria-hidden="true"></span>
						<span className="sr-only">Previous</span>
					</a>
					<a className="carousel-control-next" href="#carouselExampleIndicators1" role="button" data-slide="next">
						<span className="carousel-control-next-icon" aria-hidden="true"></span>
						<span className="sr-only">Next</span>
					</a>
				</div>
			</div>
		</>
	);
}
export default slider;