import React from 'react';
import '../../pages/fabrisorb/slider-fabrisorbwall.css';
import Bannerb1 from '../../images/newproductslider/polysorb-deco wall.webp';
import Bannerb2 from '../../images/newproductslider/polysorb-deco wall.webp';
function SliderPolysorbDecoWall() {
	return (
	  
	<>
	<div id="slider-fabrisorbwall">
		<div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Bannerb1} alt="Fabrisorb wall" />
	  				<div className="carousel-caption d-none d-md-block">
						<h2>Wall Panels</h2>
    				</div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Bannerb2} alt="Fabrisorb wall2" />
	  				<div className="carousel-caption d-none d-md-block">
		  				<h2>Colored Wall Panels</h2>
    				</div>
				</div>
    		</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span className="sr-only">Next</span>
  			</a>
		</div>
	</div>
	</>
);
}
export default SliderPolysorbDecoWall;