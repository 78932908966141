import React from 'react';
import './slider-decopanelplain.css';
import Deco1 from '../../images/flet-sm-1.webp';
import Deco2 from '../../images/flet-sm-1.webp';

function slider() {
	return (
	  
	<>
	<div id="slider-decopanelplain">
		<div id="carouselExampleIndicators2" className="carousel slide" data-ride="carousel">
  			<ol className="carousel-indicators">
    			<li data-target="#carouselExampleIndicators2" data-slide-to="0" className="active"></li>
    			<li data-target="#carouselExampleIndicators2" data-slide-to="1"></li>
  			</ol>
  			<div className="carousel-inner">
    			<div className="carousel-item active">
      				<img className="d-block w-100" src={Deco1} alt="Deco1" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h2>PLAIN COLORS</h2>
					</div>
				</div>
    			<div className="carousel-item">
      				<img className="d-block w-100" src={Deco2} alt="Deco2" />
	  				<div className="carousel-caption d-none d-md-block">
					  	<h2>PLAIN COLORS</h2>
					</div>
				</div>
			</div>
  			<a className="carousel-control-prev" href="#carouselExampleIndicators2" role="button" data-slide="prev">
    			<span className="carousel-control-prev-icon" aria-hidden="true"></span>
    			<span className="sr-only">Previous</span>
  			</a>
  			<a className="carousel-control-next" href="#carouselExampleIndicators2" role="button" data-slide="next">
    			<span className="carousel-control-next-icon" aria-hidden="true"></span>
    			<span claclassNamess="sr-only"></span>
  			</a>
		</div>
	</div>
	</>
);
}
export default slider;