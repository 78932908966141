import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import '../fabrisorb/fabrisorb-style.css';
import ScrollToTopButton from '../ScrollButton';
import SliderPolyesterTiles from './SliderPolyesterTiles';

function PolyesterCeilingTiles() {
    return (

        <>
            <Helmet>
                <title>Polyester Ceiling Tiles - Durable, Acoustic & Moisture-Resistant Ceiling Solutions</title>
                <meta name="description" content="Durable and moisture-resistant Polyester Ceiling Tiles enhance acoustics and thermal insulation, ideal for both indoor and outdoor use." />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Foamwood Panels" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabrisorb" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/fabrisorb" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/fabrisorb" />
            </Helmet>
            <Header />
            <section className="content" id="fabrisorb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className="col-sm-12 heading-fsorb">
                                    <h1>Polyester Ceiling Tiles</h1>
                                    <h4>CEILING TILES</h4>
                                    <p>Conventional ceiling tiles made from fibers such as mineral wool or glass wool fibers are having high shot content and will start releasing dust in the air stream when used as open plenum return HVAC design. Polymax ceiling tiles are having high durability along with the resistance to moisture, fungal and mold resistance makes it the right choice many indoor and outdoor ceiling tile applications. The porosity of the panels created in the process of binding the polyester fibers makes it ideal for acoustic treatment in reducing the reverberation along with acting as thermal insulation when used as ceiling tiles.</p>
                                    <SliderPolyesterTiles />

                                    <div className="flex-container mt-4">
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/polymax/polymax-brochure.pdf">Downloads</Link>
                                    </div>
                                </div>
                                <div className="col-sm-12 printing">
                                    <div className="line"></div>
                                    <div className="accordion-started accordion-bral panels row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                                            <div className="article ac-content">
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active show" data-toggle="tab" href="#home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu1">REVERBERATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="home" className="tab-pane active show"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>120kg/m³ density, Polymax insulation with acoustic fleece</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness(mm)</th>
                                                                        <td>15 - 20mm</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes(mm)</th>
                                                                        <td>600 x 600 - 1200 x 1200 & custom sizes</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>NRC 0.70 – 0.95 ( varies with thickness and mounting)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edges</th>
                                                                        <td>Plain cut</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Acoustic fleece</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Black, White</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Tested as per ASTM E84 and classified as Class A</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type A, E200 & E400</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Mounting</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu1" className="tab-pane fade"><br />
                                                            <h3>Sound Absorption Coefficients</h3>
                                                            <table className="table">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Thickness</th>
                                                                        <th scope="col">125Hz</th>
                                                                        <th scope="col">250Hz</th>
                                                                        <th scope="col">500Hz</th>
                                                                        <th scope="col">1KHz</th>
                                                                        <th scope="col">2KHz</th>
                                                                        <th scope="col">4KHz</th>
                                                                        <th scope="col">NRC</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">15mm</th>
                                                                        <td>0.50</td>
                                                                        <td>0.70</td>
                                                                        <td>0.85</td>
                                                                        <td>0.90</td>
                                                                        <td>0.90</td>
                                                                        <td>0.80</td>
                                                                        <td>0.85</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">20mm</th>
                                                                        <td>0.50</td>
                                                                        <td>0.85</td>
                                                                        <td>0.95</td>
                                                                        <td>1.00</td>
                                                                        <td>0.95</td>
                                                                        <td>0.95</td>
                                                                        <td>0.95</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu3" className="tab-pane fade"><br />
                                                            <br />
                                                            <h3>PolyMax-Ceiling Tiles : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.in/datasheet/polymax/polymax-ceiling-and-wall-usa.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default PolyesterCeilingTiles; 