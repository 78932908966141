import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import '../fabrisorb/fabrisorb-style.css';
import ScrollToTopButton from '../ScrollButton';
import SliderPolywood from './SliderPolywood';
function Polywood() {
    return (

        <>
            <Helmet>
                <title>polywood Panels</title>
                <meta name="description" content="" />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Foamwood Panels" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabrisorb" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/fabrisorb" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/fabrisorb" />
            </Helmet>
            <Header />
            <section className="content" id="fabrisorb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className="col-sm-12 heading-fsorb">
                                    <h1>POLYWOOD</h1>
                                    <h4>Acoustic Polyester Wood Panel</h4>
                                    <p>POLYWOOD Is an acoustically absorbent wood panel that offers excellent sound absorption properties along with rich aesthetic appeal for interiors. POLYWOOD are manufactured with the combination of Polyester Panel and CNC design Wood Veneer / Laminate finish which is moisture resistant & suitable for various indoor applications. POLYWOOD is light weight, non-toxic, highly durable and contains no chemical irritants or formaldehyde wherein it is an eco-friendly material also.</p>
                                    <p>POLYWOOD offers a rich aesthetic look with the wooden finish providing both sound absorption functionality along with visual appeal and is also resistant to wear and tear. POLYWOOD is great for various applications in schools, hotels, cafeterias, offices, hospitals, clinics, house of worship etc.</p>
                                    <SliderPolywood />

                                    <div className="flex-container mt-4">
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/polywood/polywood-acoustic-polyester-wood-panel.pdf" className="btn btn-danger justify-content-center">Downloads</Link>
                                    </div>
                                </div>
                                <div className="col-sm-12 printing">
                                    <div className="line"></div>
                                    <div className="accordion-started accordion-bral panels row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                                            <div className="article ac-content">
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active show" data-toggle="tab" href="#home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="home" className="tab-pane active show"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>Polyester Acoustic Panel and CNC design MDF Laminate / Wood Veneer</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness</th>
                                                                        <td>44 mm</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes</th>
                                                                        <td>300 x 300 mm, 600 x 600 mm</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>Varies with thickness and mounting<br />
                                                                            Standard Polyester Panel : 40 mm – 0.9 NRC</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Patterns</th>
                                                                        <td>Wood Finish custom Patterns/Designs by Laser Cutting</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Laminate / Veneer Finish</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Core Meets ASTM -E - 84 :21 Classification</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type - A, E - 200 & E - 400 etc.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Density</th>
                                                                        <td>60kg/m3</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu3" className="tab-pane fade"><br />
                                                            <br />
                                                            <h3>Acoustic Polyester Wood Panel : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.in/datasheet/foamwood/foamwood-acoustic-pu-wood-panel.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Polywood; 