import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import '../fabrisorb/fabrisorb-style.css';
import ScrollToTopButton from '../ScrollButton';
import SliderKnauf from './SliderKnauf';

function KnaufDanoline() {
    return (
        <>
            <Helmet>
                <title>Knauf Danoline Kraft | Premium Acoustic Ceiling and Wall Panels</title>
                <meta name="description" content="Knauf Danoline Kraft combines acoustics with aesthetics, providing high-quality soundproofing while enhancing the interior design of any space. Made from eco-conscious materials, these panels offer a sustainable solution for both noise control and visual appeal." />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Foamwood Panels" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabrisorb" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/fabrisorb" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/fabrisorb" />
            </Helmet>
            <Header />
            <section className="content" id="fabrisorb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className='mobsidebar'>
                                    <div className="col-sm-12">
                                        <div className="container">
                                            <div className="row">
                                                <Mobsidebar />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 heading-fsorb">
                                    <h1>KNAUF DANOLINE KRAFT</h1>
                                    <h4>ACOUSTIC LINING</h4>
                                    <p>KNAUF DANOLINE KRAFT acoustic linings are tested gypsum-based lining systems which are characterized by a jointless surface structure and are installed for years to meet the special requirements of design and room acoustics.</p>
                                    <SliderKnauf />

                                    <div className="flex-container mt-4">
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/asi-knauf-danoline-kraft/asi-knauf-danoline-kraft.pdf" className="btn btn-danger justify-content-center">Downloads</Link>
                                    </div>
                                </div>
                                <div className="col-sm-12 features">
                                    <h1>Features</h1>
                                    <div className="line"></div>
                                    <div className="row pad">
                                        <div className="col-md-8 col-sm-12">
                                            <ul>
                                                <li>Jointless elegant surface design</li>
                                                <li>Superior acoutics for ceilings and walls</li>
                                                <li>Environment-friendly, zero emission materials</li>
                                                <li>Efficient workmanship</li>
                                                <li>Possibility of individual, free colour design</li>
                                                <li>Quickly and easily renovated</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-4 col-sm-12 tiles bgimage">
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 features">
                                    <h1>DESIGN AND FUNCTIONALITY</h1>
                                    <div className="line"></div>
                                    <p>KNAUF DANOLINE KRAFT combines all advantages of a suspended acoustic ceiling with additional design elements.</p>

                                    <div className="line"></div>
                                    <h1>FREEDOM OF DESIGN</h1>
                                    <p>KNAUF DANOLINE KRAFT allows for a large variety of applications with individual shape and color design.</p>
                                    <p>Lights, concealed loudspeakers as well as covert airconditioning can easily be integrated into the KNAUF DANOLINE KRAFT acoustic ceiling.</p>
                                    <p>Of course the KNAUF DANOLINE KRAFT system can be renovated economically</p>
                                    <p>KNAUF DANOLINE KRAFT is also suitable for wall for example in order to reduce flutter echoes.</p>
                                    <div className="line"></div>
                                    <h1>The KNAUF DANOLINE KRAFT acoustics system consists of three components:</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12">
                                            <ul style={{ padding: "0px" }}>
                                                <li>The acoustic properties of the ceiling system are due to the perforation of the Knauf Danoline Tectopanel U Panel.</li>
                                                <li>Isolation grey primer.</li>
                                                <li>The acoustic fleece ensures the absence of cracks and serves as a smooth plaster support surface.</li>
                                                <li>The look of the KNAUF DANOLINE KRAFT acoustic ceiling is characterized by the especially fine structure of the acoustic plaster Picco S. The acoustic plaster is applied on the fleece in two layers.</li>
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="line"></div>
                                    <h1 className='extra-title'>MOUNTING - SYSTEM 1</h1>
                                    <h1 className='extra-title'>JOINT BETWEEN CEILING AND WALL</h1>
                                    <p>Use an end profile mounted with the help of gypsum screws α ≤ 500 mm in connection with an adjoining building component.</p>
                                    <h1 className='extra-title'>OPEN EXPANSION AND DILATION JOINT</h1>
                                    <p>Expansion or dilation joints must be included in calculations and established in connection with wall and ceiling surfaces clad with gypsum tiles with side lengths of 15 metres.</p>
                                    <p>It is possible to create individual joints in connection with columns and facades, for instance. We will be pleased to help you to plan projects.</p>
                                    <div className="line"></div>
                                    <h1 className='extra-title'>MOUNTING - SYSTEM 2</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12">
                                            <ul>
                                                <li>Hangers 1.5 x 20 mm metal straps / 1000 x 1000 mm c/c, screwfixed to the primary profile with 2 F/F 13 screws</li>
                                                <li>Primary profiles – P 45 (C 45x 15 mm profile) c/c 1000 mm</li>
                                                <li>Secondary profile M profile (S 25) 85 x 25 mm /300 mm, screw fixed to the primary profile with 2 F/F 13 screws in profile every cross.</li>
                                                <li>Wall angle 70 mm C profile (MSK)</li>
                                                <li>Gypsum board Tectopanel U 600 x 1200</li>
                                                <li>20 pcs. SN 35 screws / board c/c 200/300 mmscrew fixed in the perforation holes.</li>
                                                <li>Finish profile gypsum edges KS 13 profile</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>INSTALLATION WALL ANGLE</h1><br />
                                    <div className="row pad">
                                        <div className="col-md-6 col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Wall angle 70 mm C profile (MSK) fixed with hangers 1.5 x 20 mm metal straps.</li>
                                                <li>Distance between wall and MSK profile has to be max. 20 - 30 mm</li>
                                                <li>Distance between hangers 1.5 x 20 mm metal straps is max. 900 mm</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>INSTALLATION PRIMARY PROFILES</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Primary profiles – P 45 (C 45x 15 mm profile) c/c 1000 mm</li>
                                                <li>The distance to the wall and to the first/last primary profile P 45 must max. be 500 mm.</li>
                                                <li>Hangers 1.5 x 20 mm metal straps / 1000 x 1000 mm c/c, screwfixed to the primary profile with 2 F/F 13 screws</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>INSTALLATION SECONDARY PROFILE</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Secondary profile M profile (S 25) 85 x 25 mm /300 mm, screw fixed to the primary
                                                    profile with 2 F/F 13 screws in profile every cross.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>PREPARING PANELS</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Break all edges with fine sandpaper on the front so that the cardboard cannot rise during painting.</li>
                                                <li>Brush the edges free from gypsum dust and prime them with Knauf Tiefengrund (universal primer).</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>INSTALLATION</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>20 pcs. SN 35 screws / board c/c 200/300 mm- screw fixed in the perforation holes</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>CUTTING</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Cut the elements to size from the front with a fine-toothed saw.</li>
                                                <li>Prime the edges with Knauf Tiefengrund (universal primer).</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>INSTALLATION FINISH</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>After installation, please check the surface in order to get a perfect finish. There should be no surface displacements in the joints, the screw heads should be countersunk, edges should not be torn or have any irregularities, thus you get the most optimum result.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>INSTALLATION FINISH PROFILE</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Finish profile gypsum edges KS 13 profile</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>FILLING</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Check that the screws are countersunk.</li>
                                                <li>Check that the edges have been primed and are free from dust.</li>
                                                <li>Use Knauf Uniflott for filling.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>Use Knauf Uniflott for filling.</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Prepare the filler according to the instructions on the filling package.</li>
                                                <li>Fill the tube with joint filler with the help of a putty knife.</li>
                                                <li>Attach the jointing nozzle.</li>
                                                <li>Insert the tube and nozzle into a jointing gun.</li>
                                            </ul>
                                        </div>
                                    </div>
                                    <h1 className='extra-title'>FILLING</h1><br />
                                    <div className="row pad">
                                        <div className="col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Fill the joint with filler (Knauf Uniflot). Use a jointing gun or press the filler into the joint with a filling knife. Overfill slightly and make sure that the filler is pressed all the way up between the edges of the panels and avoid getting filler in the perforations.</li>
                                                <li>Press the filler up again by running a finger over the joint. Allow the filler to dry for about 45 minutes. Remove surplus filler.</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div className="line"></div>
                                <div className="flex-container mt-2 mb-4 ml-3">
                                    <Link to="https://www.acousticalsurfaces.in/datasheet/asi-knaufdanoline-kraft/asi-knauf-danoline-kraft.pdf" className="btn btn-danger justify-content-center">Datasheet</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section >
            <Footer />
        </>
    )
}

export default KnaufDanoline