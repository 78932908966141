import React from 'react';
import { Link } from 'react-router-dom';
import './footer.css';
function footer() {
  return (
    
    <>
    <footer>
    <div className="container">
        <div className="row">
            <div className="col-sm-12 social">
                <div className="sm-ft">
                    <a href="https://www.facebook.com/acousticalsurfacesindia" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-facebook" aria-hidden="true"></i>
                    </a>
                    <a href="https://twitter.com/acousticsurface" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-twitter" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.linkedin.com/company/architectural-&-acoustical-solutions?trk=hb_tab_compy_id_2725536" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-linkedin" aria-hidden="true"></i>
                    </a>
                    <a href="https://www.instagram.com/acoustical.surfaces/" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-instagram" aria-hidden="true"></i>
                    </a>
                    <a href="https://wa.me/919449777212" target="_blank" rel="noopener noreferrer">
                        <i className="fa fa-whatsapp" aria-hidden="true"></i>
                    </a>
                </div>
            </div>
        </div>
        <div className="line"></div>
        <div className="row">
            <div className="col-xl-5 col-lg-6 col-sm-12 address" >
                <p>© 2025 Acoustical Surfaces – All Rights Reserved.
                    <br/>Acoustical Supply and Installation Contractor
                    <br/>Acoustical Products Manufacturer
                    <br/>Email: <Link to="mailto:info@acousticalsurfaces.in">info@acousticalsurfaces.in</Link>
                </p>
            </div>
            <div className="col-xl-7 col-lg-6 col-sm-12 helpphone">
                <div className="helpline">
                    <span className="text">Noise Control Help Line:</span>
                    <span className="sep">
                        <Link to="tel:+919449777212">
                            <div className="phone-call">
                                <img src="https://cdn2.iconfinder.com/data/icons/social-messaging-ui-color-shapes-2-free/128/social-whatsapp-circle-512.png" width="50" alt="Call Now" title="Call Now" />
                            </div>
                            <span className="tel">+91-94497 77212</span>
                        </Link>
                    </span>
                </div>
                <ul className="ftr-list">
                    <li><Link to="/terms">Terms of Service</Link><span>|</span></li>
                    <li><Link to="/priv-policy">Privacy Policy</Link><span>|</span></li>
                    <li><Link to="/search">Search</Link></li>
                </ul>
            </div>
        </div> 
        <div className="popup d-none d-lg-block d-xl-block">
            <Link type="button" className="btn btn-primary" to="mailto:info@acousticalsurfaces.in">
                Send Message
            </Link> 
        </div>
    </div>
    </footer>
    </>
);
}
export default footer;