import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import Soundroom from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import Commonnoise from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import Cadlibrary from '../../images/additional-resources/ebook-cad-revit.webp';
import Drccinemas1 from '../../images/Project_Images/drc-1.webp';
import Drccinemas2 from '../../images/Project_Images/DRC_2.webp';
import Drccinemas3 from '../../images/projects-slider/drc-mysore.webp';
import ScrollToTopButton from '../ScrollButton';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';
function Acousticdrc() {
    const sliderRef = useRef(null);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
    };

    const goToPrev = () => {
        sliderRef.current.slickPrev();
    };

    const goToNext = () => {
        sliderRef.current.slickNext();
    };

    return (

        <>
            <Helmet>
                <title>About Our DRC Cinemas Soundproofing Acoustics Project </title>
                <meta name="description" content="" />
                <meta property="og:title" content="DRC Cinemas" />
                <meta property="og:description" content="Top of the line equipment for acoustics and projection giving beautiful ambience topped off with stylish lobby and concession counters screens films of all popular languages." />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/projects-slider/drc-mysore.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/acoustic-projects/project-drc-cinemas" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/acoustic-projects/project-drc-cinemas" hreflang="en-in" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="IN" />
                <meta name="geo.region" content="india" />
            </Helmet>
            <Header />
            <section className="content">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                            </div>
                            <div className="image-slider pro-content">
                                <div id="caption-slide" className="slider-container">
                                    <div className="slider">
                                        <Slider {...settings} ref={sliderRef}>
                                            <div className="slide">
                                                <img src={Drccinemas1} className="img-fluid" alt="DRC Cinemas" />
                                            </div>
                                            <div className="slide">
                                                <img src={Drccinemas2} className="img-fluid" alt="DRC Cinemas" />
                                            </div>
                                            <div className="slide">
                                                <img src={Drccinemas3} className="img-fluid" alt="DRC Cinemas" />
                                            </div>
                                        </Slider>
                                    </div>
                                    <div className="switch" id="prev" onClick={goToPrev} style={{ backgroundColor: "transparent" }}><span>&#10094;</span></div>
                                    <div className="switch" id="next" onClick={goToNext} style={{ backgroundColor: "transparent" }}><span>&#10095;</span></div>
                                </div>
                            </div>
                            <div className="row pro-content">
                                <div className="col-sm-12 writeup">
                                    <h3>DRC Cinemas</h3>
                                    <p className="note">Location: Mysore</p>
                                    <p><strong>About DRC Cinemas Acoustic Project</strong>
                                        <br />Where Dreams Unfold on the Big Screen : In the heart of Mysore, DRC Cinemas stands tall—a beacon of entertainment and community. As the city’s first multiplex, it brings to life the dreams of countless Mysoreans. Top-of-the-line acoustics and projection, creates a mesmerizing ambiance. The stylish lobby and concession counters invite moviegoers, while diverse language films captivate audiences on the screens.<br />
                                    </p>
                                    <p>
                                        <strong> Our Scope</strong>
                                        <br />In this project, we enhanced the auditory experience by ensuring that every whisper, every explosion, and every note resonates flawlessly within the theatre walls. It’s more than just sound—it’s an immersive journey.
                                    </p>
                                    <br />
                                    <hr />
                                </div>
                            </div>
                            <div className="row resources">
                                <div className="col-xl-12 col-lg-12 col-sm-12">
                                    <h2>Additional Resources</h2>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Creating Better-Sounding Rooms</h6>
                                    <img className="hvr-grow" src={Soundroom} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/bs-rooms-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}>Solutions to Common Noise Problems</h6>
                                    <img className="hvr-grow" src={Commonnoise} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/noise-problems-guide">Download eBook</Link></div>
                                </div>
                                <div className="col-xl-4 col-lg-4 col-sm-12">
                                    <h6 style={{ textAlign: "center" }}><br />CAD, CSI, & Revit Library </h6>
                                    <img className="hvr-grow" src={Cadlibrary} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                    <div className="butn"><Link to="/cad-library-guide">Access Product Cad Files</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Acousticdrc;