import React from 'react';
import {Helmet} from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Mobsidebar from '../mobile-sidebar';
import Megamenu from '../megamenu';
import ASIManufacturer from '../../images/index-b2.webp';
import SoundRooms from '../../images/additional-resources/ebook-acoustics-demyst.webp';
import NoiseProb from '../../images/additional-resources/Common-Noise-Problems-3D01x.webp';
import CADCSI from '../../images/additional-resources/ebook-cad-revit.webp';
import Carouselhome from './carousel-qatar';
import ASIProducts from '../../images/novo-cinemas.webp';
import ScrollToTopButton from '../ScrollButton';
function home() {
  return (
    
    <>
    <Helmet>
	    <meta name="robots" content="index, follow" />
	    <meta name="geo.placename" content="Qatar"/>
        <meta http-equiv="content-language" content="ar-qa" />
        <meta name="geo.region" content="Qatar" />
        <title>High-Performance Acoustic Panels Qatar | Noise Control Experts</title>
        <meta name="description" content="We are the Acoustic Baffles and Ceilling Panels manufacturer, ASI has been providing various Decorative Acoustic Products at Qatar."/>
        <meta property="og:title" content="Acoustic Panels Manufacturer and Products Supplier Qatar" />
        <meta property="og:description" content="We are the manufacturer of Acoustic Products, ASI has been providing various Noise Control Solutions for Qatar."/>
        <meta property="og:image" content="https://www.acousticalsurfaces.in/images/logo1.svg" />
        <meta property="og:locale" content="ar-qa" />
        <link href="https://www.acousticalsurfaces.in/qatar" rel="alternate" hreflang="ar-qa" />
        <link rel="canonical" href="https://www.acousticalsurfaces.in/qatar" />
    </Helmet>
    <Header />
    <section className="content" id="home">
        <div className="container">
            <div className="row">
                <div className=" col-sm-3 sidemenu">
                  <Sidebar />  
                </div>
                <div className="col-sm-9 main_content nopaddding">
                    <div className="container index">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="carousel">
                                    <div className="megamenu">
                                        <Megamenu />
                                    </div>
                                    <div className='mobsidebar'>
                                        <div className="col-sm-12">
                                            <div className="container">
                                                <div className="row">
                                                    <Mobsidebar />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className="image-slider">
                                    <section className="hero">
                                        <div className="hero__image-wrapper">
                                            <img src={ASIProducts} alt="Acoustical Products Manufacturer" id="hero__image" />
                                        </div>
                                        <div className="hero__text">
                                            <p>We are</p>
                                            <h1>Acoustical Surfaces</h1>
                                            <p>@</p>
                                            <h1>QATAR</h1>
                                            <a href="/international">
                                                <button id="hero__img-refresh">Contact us</button>
                                            </a>
                                        </div>
                                    </section>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="abt">
                            <div className="row">
                                <div className="col-sm-12 col-md-6">
    	                        <br />
                                    <h2><strong>Acoustical Surfaces,</strong> has been providing acoustic solutions to soundproofing, noise control,
                                    acoustical and vibration problems for over<span style={{color:"#f13849"}}> 40 years.</span></h2>
                                    <br />
                                    <p>
                                    With over 400 specialty soundproofing and noise control products and Sound Absorbing Materials, ASI has a solution for almost any noise problem. 
                                    Call<Link to="tel:+918277331000" style={{textDecoration: "none", fontSize: ".9em", textAlign: "justify", color: "#f13849", lineHeight: "1.3em"}}>+91-82773 31000</Link>, <a href="tel:+918025722022" style={{textDecoration: "none", fontSize: ".9em", textAlign: "justify", color: "#f13849", lineHeight: "1.3em"}}>+91-80257 22022</a>, <a href="tel:+919449777212" style={{textDecoration: "none", fontSize: ".9em", textAlign: "justify", color: "#f13849", lineHeight: "1.3em"}}>+91-94497 77212</a> to speak with one of our knowledgeable soundproofing and noise control specialists. Our specialists can assist with design and 
                                    offer onsite or over the phone consultation during normal business hours Monday – Saturday, from 9am – 6pm.
                                    </p>
                                </div>
                                <div className="col-sm-12 col-md-6">
                                    <iframe src="https://www.youtube.com/embed/__WvZ_vbkEA" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="Acoustics"></iframe>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12 d-none d-md-block banner">
                            <Link to="/contact-us">
                                <img src={ASIManufacturer} alt="We are the manufacturers" />
                            </Link>
                        </div>
                        <br />
                        <div className="ourprojects">
                            <h5>OUR PROJECTS</h5>
                            <div id="mixedSlider">
                                <div className="MS-content">
                                    <Carouselhome />
                                </div>
                            </div>
                            <br /><br /> 
                        </div>
                        <br />
                        <div className="lastintro text-center">
                            <h2>SUPPLY AND INSTALLATION OF ACOUSTIC PANELS</h2>
                            <h3>Acoustical Surfaces offers everything you need for Commercial, Industrial, 
                                Educational, House of Worship, Pro Audio, OEM, Home Theater and other Residential applications.</h3>
                            <p>For noise control applications Echo Eliminator™ is recommended. Echo Eliminator™ is a high performance noise control product made from recycled cotton. For soundproofing ceilings, Sound Silencer™ is a great option. This dual function acoustical paneling blocks and absorbs sound. If you’re going after the “recording studio” look,
                                we offer a full line of acoustic foam. For more soundproofing information visit our soundproofing page or blog.</p>
                        </div>
                        <br />
                        <div className="line"></div>
                        <br />
                        <div className="clearfix"></div>
                        <div className="row resources">
                            <div className="col-xl-12 col-lg-12 col-sm-12">
                                <h2>Additional Resources</h2>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Creating Better-Sounding Rooms</h6>
                                <img className="hvr-grow" src={SoundRooms} alt="Create Better Sounding Rooms" target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}>Solutions to Common Noise Problems</h6>
                                <img className="hvr-grow" src={NoiseProb} alt="Common Noise Problems" target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Download eBook</a>                                
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-sm-12">
                                <h6 style={{textAlign: "center"}}><br />CAD, CSI, & Revit Library </h6>
                                <img className="hvr-grow" src={CADCSI} alt="CAD Library" target="_blank" rel="noopener noreferrer" />
                                <div className="butn">
                                    <a href="https://api.whatsapp.com/send?phone=918277331000&text=Hi" target="_blank" rel="noopener noreferrer">Access Product Cad Files</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ScrollToTopButton />
    </section>
<Footer />    
</>
);
}
export default home;
