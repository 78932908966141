import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import '../fabrisorb/fabrisorb-style.css';
import ScrollToTopButton from '../ScrollButton';
import SliderEchotherm from './sliderEchotherm';
function Ecotherm() {
    return (

        <>
            <Helmet>
                <title>Acoustic & Thermal Solutions – Carbon-Neutral Ceiling Plaster</title>
                <meta name="description" content="Discover Echotherm, the world’s first carbon-neutral acoustic and thermal ceiling plaster that combines aesthetic appeal with superior sound control for diverse spaces." />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Foamwood Panels" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabrisorb" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/fabrisorb" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/fabrisorb" />
            </Helmet>
            <Header />
            <section className="content" id="fabrisorb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className='mobsidebar'>
                                    <div className="col-sm-12">
                                        <div className="container">
                                            <div className="row">
                                                <Mobsidebar />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-sm-12 heading-fsorb">
                                    <h1>ECHOTHERM</h1>
                                    <p>We launched the world’s first carbon-neutral acoustic & thermal ceiling plastering solution and initiated the production of the eco-conscious ECOTHERM Acoustic and Thermal Spray Plaster. Presently, we produce and market top-tier, environmentally conscious product concepts designed to enhance room acoustics and minimize reverberation. Our seamless offerings provide ceilings with a visually appealing aesthetic and exceptional acoustic performance. They find applications in a diverse array of settings, spanning historical structures, upscale residential properties, commercial establishments, retail spaces, and educational facilities.</p>
                                    <SliderEchotherm />

                                    <div className="flex-container mt-4">
                                        <Link to="https://www.acousticalsurfaces.in/catalogue/ecotherm/ecotherm-catalogue.pdf" className="btn btn-danger justify-content-center">Downloads</Link>
                                    </div>
                                </div>
                                <div className="col-sm-12 features">
                                    <h1>ECOTHERM</h1>
                                    <h6>ACOUSTIC & THERMAL PLASTER</h6>
                                    <div className="line"></div>
                                    <p>Our ECOTHERM acoustic and thermal spray plaster solution emphasizes sustainability, resulting in a classic, streamlined, industrial appearance. ECOTHERM is versatile and can be applied directly to nearly any surface. This quick, cost-effective seamless acoustic spraying solution simplifies the design and installation process, requiring only layer thickness and color choices for specification. We offer three standard colors options.</p>

                                    <div className="line"></div>
                                    <h1>Three Standard Colours</h1>
                                    <p>Currently we offer the following standard colours: White Medium, Light Gray, and Dark Gray. Other colours are possible upon request.</p>
                                    <div className="col-md-6 col-sm-12 d-none d-md-block">
                                        <ul>
                                            <li>White Medium</li>
                                            <li>Light Gray</li>
                                            <li>Hotels</li>
                                        </ul>
                                    </div>
                                    <p>Our commitment to eco-friendliness is demonstrated through the creation of ECOTHERM, Acoustic and Thermal Spray Plaster, an environmentally conscious alternative for the seamless ceiling plaster treatment.</p>
                                    <p>ECOTHERM acoustic and thermal spray plaster is designed for both ceilings and walls. Its impressive sound-absorbing capabilities make it an excellent choice for achieving optimal acoustic control in various environments. Its versatility allows direct application on almost any surface, including both straight and curved walls. This offers a more flexible and adjustable option compared to conventional solutions like suspended ceilings and acoustic panels.</p>
                                    <p>Our high-quality and environmentally friendly acoustic spray effectively absorbs unwanted noise, suitable for a wide spectrum of settings. It’s particularly wellsuited for the rapidly growing open ceiling design trend. With modern research and studies, there are several ways to create an illusion of a bigger space, even with a smaller space. An open ceiling is one such idea that opens up a space to give a more big and fresh look to any space, whether it’s an office, a commercial space or even a restaurant.</p>
                                    <div className="line"></div>
                                    <h1 className='extra-title'>ZERO CARBON ACOUSTIC SPRAY</h1>
                                    <p>The carbon-neutral ECOTHERM acoustic Acoustic and Thermal spraying plater solution is a combination of recycled paper, wood adhesive and water. This when installed by a professional installer will give you an elegant space which completes the look desired by the client. The tubular structure of the cellulosic fibers present in ECOTHERM creates the right reason for the effective reverberation energy decay in the space.
                                    </p>
                                    <h1 className='extra-title'>SEAMLESS AND SIMPLE INSTALLATION</h1>
                                    <p>ECOTHERM is easy to design because of the single layer treatment required to achieve the required absorption values. Architects / Engineers prefer simple procedures of treatment over complicated finishes which will make the design process and execution much easier and saves money in the process.</p>
                                    <p>ECOTHERM acoustic Acoustic and Thermal spray plaster system offer several advantages over the conventional ceiling treatment, when compared with the combined benefit of thermal and acoustic comfort achieved when it is used as the solution.</p>
                                    <p>The seamless finish contributes to a clean and aesthetically pleasing appearance making it suitable for various design styles. It can be applied to concrete, drywall, metal and many more. This versatility makes it practical choice for both new construction and renovations.</p>
                                    <h1 className='extra-title'>FAST AND COST- EFFECTIVE INSTALLATION</h1>
                                    <p>Acoustic spraying is directly applied to surfaces of your choice, whether it’s concrete, plasterboard, or virtually any chosen substrate. You can choose layer thickness ranging from 5 mm to 40 mm to achieve the desired absorption values. Even layer thicknesses exceeding 40 mm can be accommodated in special cases. All ceiling services can be freely installed, eliminating the need for meticulous point-by-point measurements. This streamlined approach not only conserves planning time but also reduces overall preparation expenses.</p>
                                    <h1 className='extra-title'>EASY TO MAINTAIN AND REPAIR</h1>
                                    <p>An open ceiling design ensures effortless accessibility for maintenance and repair tasks to all the services running through the ceiling. This is very user friendly and allows the clients to access the services in the event of any breakdown and conserves both access and service installation time and associated costs when compared with a conventional ceiling.</p>
                                    <div className="line"></div>
                                    <h1 className='extra-title'>Technical features:</h1>
                                    <p>The acoustic classification for ECOTHERM Zero Carbon Acoustic and Thermal Spray Plaster is determined by the layer thickness. For example, 35mm acoustic spraying provides Class A absorption.</p>
                                    <h1 className='extra-title'>Fire class:</h1>
                                    <p>B–s1, d0. (EN 13501-1)</p>
                                    <h1 className='extra-title'>Weighted absorption coefficient, alpha W:</h1>
                                    <p>0.9</p>
                                    <h1 className='extra-title'>Weighted absorption coefficient, alpha W:</h1>
                                    <p>Class A (ECOTHERM Acoustic and Thermal Spray 35mm)</p>
                                    <p>Class C (ECOTHERM Acoustic and Thermal Spray 25mm)</p>
                                    <h1 className='extra-title'>UV and Humidity:</h1>
                                    <p>The test corresponds to atleast 10 years in a real environment.UV radiation causes some yellowing in the white materials. No other colour changes perceptible to eye. Exposure to high relative humidity did not cause any changes.</p>
                                    <h1 className='extra-title'>Emission classification (M1):</h1>
                                    <p>ECOTHERM acoustic and Thermal spray is M1 indoor air certified</p>
                                    <h1 className='extra-title'>Breathability:</h1>
                                    <p>The material is breathable</p>

                                </div>
                                <div className="line"></div>
                                <div className="flex-container mt-2 mb-4 ml-3">
                                    <Link to="https://www.acousticalsurfaces.in/datasheet/ecotherm/ecotherm-catalogue.pdf" className="btn btn-danger justify-content-center">Datasheet</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Ecotherm; 