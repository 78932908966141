import React from 'react';
import { Helmet } from 'react-helmet';
import Header from '../header';
import Footer from '../footer';
import Sidebar from '../sidebar';
import Megamenu from '../megamenu';
import Mobsidebar from '../mobile-sidebar';
import '../fabrisorb/fabrisorb-style.css';
import ScrollToTopButton from '../ScrollButton';
import SliderDecowood from './SliderDecowood';
import SliderPetWood from './SliderPetWood';
import SliderGroovedWood from './SliderGroovedWood';
import { Link } from 'react-router-dom';
function Decowood() {
    return (

        <>
            <Helmet>
                <title>Premium Decowood Panels for Modern, Eco-Friendly Acoustic Solutions</title>
                <meta name="description" content="Bring natural elegance and excellent sound quality into your space with Decowood Panels. Made from sustainable materials, these eco-friendly panels provide optimal acoustic performance and stunning design, perfect for enhancing any commercial or residential interior." />
                <meta name="robots" content="index, follow" />
                <meta name="geo.placename" content="India" />
                <meta http-equiv="content-language" content="in" />
                <meta name="geo.region" content="india" />
                <meta property="og:title" content="Foamwood Panels" />
                <meta property="og:description" content="" />
                <meta property="og:image" content="https://www.acousticalsurfaces.in/images/tiles.webp" />
                <meta property="og:locale" content="en-in" />
                <link rel="alternate" href="https://www.acousticalsurfaces.ae/fabrisorb" hreflang="en-ae" />
                <link rel="alternate" href="https://www.acousticalsurfaces.in/fabrisorb" hreflang="en-in" />
                <link rel="canonical" href="https://www.acousticalsurfaces.in/fabrisorb" />
            </Helmet>
            <Header />
            <section className="content" id="fabrisorb">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-3 sidemenu">
                            <Sidebar />
                        </div>
                        <div className="col-sm-9 product-page nopaddding">
                            <div className="container thanks">
                                <div className="megamenu">
                                    <Megamenu />
                                </div>
                                <div className="col-sm-12 heading-fsorb">
                                    <h1>DECO WOOD</h1>

                                    <p>DECOWOOD are absorption panels with outstanding acoustic performance with a combination of Non-Woven Polyester Fiber and Veneer Wooden Finish. These panels give an aesthetic feel to the areas and have good absorption properties.</p>
                                    <p>DECOWOOD acoustic panels are perfect for any space where good speech privacy or speech intelligibility is important.</p>
                                    <SliderDecowood />
                                </div>

                                <div className="col-sm-12 features">
                                    <h1>Applications</h1>
                                    <div className="line"></div>
                                    <div className="row pad">
                                        <div className="col-md-6 col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Theatres</li>
                                                <li>Conference Rooms</li>
                                                <li>Educational Instititions</li>
                                                <li>Convention Centre's</li>
                                                <li>Banquet Halls</li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 col-sm-12 d-none d-md-block">
                                            <ul>
                                                <li>Multiplexes</li>
                                                <li>Colleges</li>
                                                <li>Corporate Offices</li>
                                                <li>Auditoriums</li>
                                                <li>Hospitals</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-sm-12 printing">
                                    <div className="col-sm-12 more">
                                        <h1>More Products</h1>
                                        <div className="line"></div>
                                    </div>
                                    <div className="accordion-started accordion-bral panels row">
                                        <div className="col-sm-12 col-md-12 col-lg-12 ">
                                            <label className="ac-label" htmlFor="ac-1">
                                                <b>DECOWOOD</b><br />Acoustic PET - Wood Panel
                                            </label>
                                            <div className="article ac-content">
                                                <SliderPetWood />
                                                <p> DECOWOOD are highly absorptive panels that captivates visual and tactile experience, adding depth, sophistication, and modernity with an aesthetic look to any space suitable for interior finishing. DECOWOOD has high sound absorption properties and is a great solution for reverberation related issues. It forms an effective remedy for reverberation control in interior spaces and is customizable to required designs.<br /><br />DECOWOOD is manufactured with a combination of Non-Woven Polyester Fibre and Laminated Wood Panel. These wood panels are made from the finest quality wood and are available in various shades. It gives a rich aesthetic look with the Laminated Wooden Panels. DECOWOOD is eco-friendly and suitable for walls & ceilings. They are used in various applications such as Offices, Restaurants, Retail Outlets, Meeting Rooms, Conference Rooms etc.</p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active show" data-toggle="tab" href="#home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="home" className="tab-pane active show"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>Acoustic PET Panel and MDF Laminate</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness</th>
                                                                        <td>27 mm (10 mm Acoustic PET- Plain + 17 mm MDF Laminate)<br />
                                                                            21 mm (10 mm Acoustic PET- Plain + 11 mm MDF Laminate)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes</th>
                                                                        <td>2400 x 600 mm</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>Varies with thickness and mounting<br />
                                                                            Standard Acoustic PET 10 mm – 0.35 NRC</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Laminate Finish – Wooden Shades</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Laminate Finish</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Meets B-S1, d0 classification in accordance to EN 13501 - 1: 2007- test standard</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type - A, E200 & E - 400 etc.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Density</th>
                                                                        <td>196kg / m3</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="menu3" className="tab-pane fade"><br />
                                                            <br />
                                                            <h3>Acoustic PET - Wood Panel : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.in/datasheet/decowood/decowood-acoustic-pet-wood-panel.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="line"></div>
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                            <label className="ac-label" htmlFor="ac-2">
                                                <b>DECOWOOD</b><br />Acoustic Grooved PET – Wood Panels<i></i>
                                            </label>
                                            <div className="article ac-content">
                                                <SliderGroovedWood />
                                                <p>DECOWOOD enriches the aesthetic appeal of interiors with its elegant in MDF Laminate /
                                                    Wood Veneer finish. These panels are efficient with good absorption properties that merge
                                                    with rich look for interiors that enhances the beauty of the space. DECOWOOD is a unique
                                                    designed product manufactured with the combination of Acoustic PET Grooved Panel and
                                                    MDF Laminate / Wood Veneer Panel.<br /><br />Our DECOWOOD panels are having high absorption properties with non-woven polyester
                                                    fiber as its core which plays a vital role in sound absorption. Wood Veneer panels are high
                                                    quality wood products made from compressed wood fibers which has a natural wood grain.
                                                    DECOWOOD gives charm and premium look to any space. DECOWOOD is durable, stable
                                                    & has smooth surface and has various shades. DECOWOOD can be easily installed on walls
                                                    and ceilings are used for various applications in Offices, Educational Institutions, Restaurants,
                                                    Retail Outlets, Meeting Rooms, Conference Rooms etc.</p>
                                                <h2>Product Details</h2>
                                                <br />
                                                <div className="col-sm-12">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item">
                                                            <a className="nav-link active" data-toggle="tab" href="#cp-home">SPECIFICATION</a>
                                                        </li>
                                                        <li className="nav-item">
                                                            <a className="nav-link" data-toggle="tab" href="#cp-menu3">DATASHEET</a>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        <div id="cp-home" className="container tab-pane active"><br />
                                                            <table className="table">
                                                                <tbody>
                                                                    <tr>
                                                                        <th scope="row">Core</th>
                                                                        <td>Acoustic Grooved PET Panel and MDF
                                                                            Laminate / Wood Veneer</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Thickness</th>
                                                                        <td>15mm (10 mm : Acoustic PET Panel + 5 mm
                                                                            MDF Laminate / Wood Veneer)</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Sizes</th>
                                                                        <td>2400 x 600 mm</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Acoustic Properties</th>
                                                                        <td>Varies with thickness and mounting
                                                                            Standard Acoustic PET : 10 mm – 0.35 NRC</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Colours</th>
                                                                        <td>Laminate Finish – Wooden Shades</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Edge Finishes</th>
                                                                        <td>Square</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Facing</th>
                                                                        <td>Laminate / Veneer Finish</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Flamability</th>
                                                                        <td>Meets B-s1, d0 clasification when
                                                                            tested according EN 13501 - 1 : 2007 - standard</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Mounting type</th>
                                                                        <td>Type - A, E - 200 & E - 400 etc.</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Installation</th>
                                                                        <td>Adhesive</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <th scope="row">Density</th>
                                                                        <td>196kg/m3</td>
                                                                    </tr>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                        <div id="cp-menu3" className="container tab-pane fade"><br />
                                                            <br />
                                                            <h3>Acoustic Grooved PET – Wood Panels : Datasheet</h3>
                                                            <br />
                                                            <a target="_blank" rel="noopener noreferrer" href="https://www.acousticalsurfaces.in/datasheet/decowood/decowood-grooved-pet-wood-panel.pdf" className="brochure">Download</a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <Link to={'/foamwood'} style={{ textDecorationLine: "none" }}><p>Foamwood</p></Link>
                                    <Link to={'/polywood'} style={{ textDecorationLine: "none" }}><p>Polywood</p></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='mobsidebar'>
                        <div className="col-sm-12">
                            <div className="container">
                                <div className="row">
                                    <Mobsidebar />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ScrollToTopButton />
            </section>
            <Footer />
        </>
    );
}
export default Decowood; 